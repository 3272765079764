<template>
  <div>
    <ConsultAdd
      :is-add-new-consult-sidebar-active.sync="isAddNewConsultSidebarActive"
      @createConsult="createConsult"
    />
    <b-sidebar
      id="add-new-appointment-sidebar"
      :visible="isAddNewAppointmentSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      z-index="1300"
      @hidden="resetForm"
      @change="
        (val) => $emit('update:is-add-new-appointment-sidebar-active', val)
      "
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            {{ $t("Add") }} {{ $t("NewV2") }} {{ $t("Appointment") }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <validation-provider
              #default="validationContext"
              name="Patient"
              rules="required"
            >
              <b-form-group :label="$t('Patient')" label-for="patient">
                <b-form-input
                  disabled
                  :value="`${userAppointment.name} ${userAppointment.lastname}`"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Type Visit"
              rules="required"
            >
              <b-form-group
                :label="$t('Types of visits')"
                label-for="type visit"
              >
                <v-select
                  id="type visit"
                  v-model="stateData.typeVisit"
                  :state="getValidationState(validationContext)"
                  :options="optionsTypeVisit"
                  @search="searchTypeVisit"
                  :reduce="(option) => option.id"
                  :clearable="false"
                  label="name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Visit Reason"
              rules="required"
            >
              <b-form-group label-for="type_consult">
                <div class="d-flex justify-content-between">
                  <label for="type_consult">{{ $t("Visit Reason") }}</label>
                  <b-button
                    size="sm"
                    variant="success"
                    class="btn-icon button-custom"
                    @click="isAddNewConsultSidebarActive = true"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
                <v-select
                  id="type_consult"
                  v-model="stateData.typeConsultation"
                  :state="getValidationState(validationContext)"
                  :options="optionsTypeConsult"
                  @search="searchTypeConsult"
                  :clearable="false"
                  label="name"
                >
                </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-show="checkCodeQr"
              #default="validationContext"
              name="Qr"
              :rules="checkCodeQr ? 'required' : null"
            >
              <b-form-group label="Qr" label-for="Qr">
                <b-form-input
                  id="Qr"
                  v-model="stateData.codeQr"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Code qr"
                />
              </b-form-group>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <b-button
              @click="scanPromotion"
              :variant="checkCodeQr ? 'primary' : 'outline-primary'"
            >
              Scan QR
            </b-button>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">{{ $t("Add") }}</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-danger"
                @click="hide"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastNotification from "@/components/ToastNotification";
import { notificationCheck } from "@/mixins/NotificationSetup";

import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { required } from "@validations";
import { onMounted, ref, watch } from "@vue/composition-api";

import axiosA from "@/services/appointment";
import axiosAC from "@/services/appointment/actions";

import axiosTV from "@/services/admin/consults/visits";
import axiosC from "@/services/admin/consults/consults";
import ConsultAdd from "@/views/admin/type-consult/consult/ConsultAdd";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    SpinnerLoading,
    ConsultAdd,
    vSelect,
    flatPickr,
    ToastNotification,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewAppointmentSidebarActive",
    event: "update:is-add-new-appointment-sidebar-active",
  },
  props: {
    isAddNewAppointmentSidebarActive: {
      type: Boolean,
      required: true,
    },
    userAppointment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const isAddNewConsultSidebarActive = ref(false);
    const stateDataTem = {
      userType: "",
      patient: null,
      typeVisit: null,
      typeConsultation: null,
      scanQr: null,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetstateData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };

    const checkCodeQr = ref(false);
    const toast = ref(null);

    watch(
      () => stateData.value.codeQr,
      (newValue, oldValue) => {
        if (newValue !== null || newValue.length > 0) {
          const data = {
            codeQr: stateData.value.codeQr,
          };
          axiosAC.appointmentAssignCuppon(data).then((res) => {
            const { type, message } = notificationCheck(res);
            toast.value[type](message);
          });
        }
      }
    );

    const scanPromotion = () => {
      if(checkCodeQr.value){
        stateData.value.codeQr = null
      }
      checkCodeQr.value = !checkCodeQr.value;
    };

    const createConsult = () => {
      loadTypeConsult();
      isAddNewConsultSidebarActive.value = false;
    };

    const optionsTypeVisit = ref([]);
    const optionsTypeConsult = ref([]);
    const clearTimeoutBuscador = ref("");

    onMounted(() => {
      loadTypeVisit();
      loadTypeConsult();
    });

    const loadTypeVisit = () => {
      axiosTV.visitListActive(10).then(({ data }) => {
        const findItem = data.findIndex((item) => item.id === 3);
        data.splice(findItem, 1);
        optionsTypeVisit.value = data;
      });
    };
    const loadTypeConsult = () => {
      axiosC.consultList(10).then(({ data }) => {
        optionsTypeConsult.value = data;
      });
    };
    const searchTypeVisit = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosTV.visitListActiveFilter(10, datos).then(({ registro: { data } }) => {
            loading(false);
            const findItem = data.findIndex((item) => item.id === 3);
            data.splice(findItem, 1);
            optionsTypeVisit.value = data;
          });
        }, 400);
      }
    };
    const searchTypeConsult = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosC.consultFilter(10, datos).then(({ data }) => {
            loading(false);
            optionsTypeConsult.value = data;
          });
        }, 400);
      }
    };
    const loading = ref(false);
    const onSubmit = async () => {
      loading.value = true;

      const datos = {
        user_type: "established",
        patients_id: props.userAppointment.id,
        type_visits_id: stateData.value.typeVisit,
        type_consultations_id: stateData.value.typeConsultation.id,
        codeQr: stateData.value.codeQr
      };
      await axiosA
        .appointmentCreate(datos)
        .then((res) => {
          const { type, message } = notificationCheck(res);
          emit("createAppointment", {
            type,
            message,
          });
        })
        .catch(() => {
          loading.value = false;
          const { type, message } = notificationCheck(res);
          emit("createAppointment", {
            type,
            message,
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetstateData);
    const config = {
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      static: true,
    };
    return {
      loading,
      stateData,
      optionsTypeVisit,
      optionsTypeConsult,
      toast,
      checkCodeQr,
      scanPromotion,
      isAddNewConsultSidebarActive,
      createConsult,
      searchTypeConsult,
      searchTypeVisit,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      config,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.button-custom {
  padding: 5px !important;
}
</style>