<template>
  <div>
    <AppointmentFilter :loading="loading" @searchFilter="searchFilter" @filterPage="filterPage" />
    <b-card class="table-expand">
      <TitleTable titleTable="Resource Schedule" iconTable="CalendarIcon" />
      <div class="m-1">
        <scanQr v-if="checkCodeQrModal" :userId="userAppointmentId" typeQr="bfCheckout"
          @closeModalPay="closeModalMembership" @successModalQr="successModalQr" />
        <AppointmentEdit v-if="isEditAppointmentSidebarActive" :is-edit-Appointment-sidebar-active.sync="isEditAppointmentSidebarActive
          " :itemEdit="itemEdit" @editAppointmentSuccess="editAppointmentSuccess" />
        <AppointmentPay v-if="conditionPayAppointment" :itemAppointment="itemToPay" @closeModalPay="closeModalPay"
          @paymentChecked="paymentChecked" @NotificationCheck="NotificationCheck"
          @updateItemAppoiment="handleUpdateAppoiment" />

        <!-- copay -->
        <AppointmentPayCopay v-if="conditionPayCopayAppointment" :itemAppointment="itemToPay"
          @closeModalPay="closeModalPay" @paymentChecked="paymentChecked" @NotificationCheck="NotificationCheck"
          @updateItemAppoiment="handleUpdateAppoiment" :isInsurance="itemToPay.is_insurance_appointment" />

        <AppointmentCancel v-if="conditionCancelAppointment" :itemAppointment="itemToPay" @closeModalPay="closeModalPay"
          @paymentChecked="paymentChecked" @NotificationCheck="NotificationCheck" />
        <!-- <AppointmentAdd
          :is-add-new-appointment-sidebar-active.sync="
            isAddNewAppointmentSidebarActive
          "
          @createAppointment="createAppointment"
        /> -->
        <b-row>
          <b-col cols="12" md="12" class="d-flex align-items-center justify-content-between flex-wrap mb-1 mb-md-0">
            <div>
              <label>{{ $t("Show") }}</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                class="per-page-selector d-inline-block mx-50" />
              <label>{{ $t("Entries") }}</label>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <!-- <b-button
                variant="primary"
                @click="isAddNewAppointmentSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Appointment</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <LoadingTable v-if="items === null" :columns="fields.length" />
      <b-table v-else :items="items" :fields="fields" striped responsive bordered hover
        class="position-relative text-center" primary-key="id" :sort-by.sync="sortBy" show-empty
        empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
        <template #cell(show)="row">
          <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1 d-flex">
                <strong>Patient : </strong>
                <b-link :to="{
                  name: 'patients-view',
                  params: { id: row.item.patients.id },
                }">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.patients.name }}
                    {{ row.item.patients.lastname }}
                  </span>
                  <small class="text-muted">{{
                    row.item.patients.email
                    }}</small>
                </b-link>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Visit Date : </strong>{{ row.item.visit_date }}
                {{ row.item.lastname }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t("Visit Reason") }} : </strong><span>{{
                  row.item.type_consultations
                    ? row.item.type_consultations.name
                    : "Not Found"
                }}</span>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Type Visit : </strong>
                <span v-if="row.item.type_visits">
                  <span :style="`text-decoration: underline ${row.item.type_visits.color}`">{{ row.item.type_visits.name
                    }}</span>
                </span>
                <span v-else>Not Found</span>
              </b-col>
            </b-row>

            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(patients)="data">
          <div class="d-flex">
            <b-link :to="{
              name: 'patients-view',
              params: { id: data.item.patients.id },
            }" class="text-left">
              <p class="font-weight-bold d-flex m-0 pl-1">
                {{ data.item.patients.name }} {{ data.item.patients.lastname }}
              </p>
              <small class="text-muted d-flex pl-1">{{
                data.item.patients.email
                }}</small>
            </b-link>
          </div>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name">
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
                }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(facilities)="data">
          <b-link :to="{
            name: 'admin-facilities-view',
            params: { id: data.item.facilities.id },
          }">
            <span>{{ data.item.facilities.name }}</span>
          </b-link>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge :style="{
              'background-color': data.item.office_visit.visit_statuses.color,
            }" v-b-tooltip.hover.top="data.item.office_visit.visit_statuses.name" class="badge-glow cursor-pointer">
              <span class="align-text-top text-capitalize">{{
                data.item.office_visit.visit_statuses.prefix
                }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(frontdesks)="data">
          <div class="d-flex justify-content-center align-items-center">
            <b-avatar size="32" :src="data.item.frontdesks.avatar" />
            <b-link :to="{
              name: 'web-settings-users',
              params: { id: data.item.frontdesks.id },
            }" class="text-left">
              <p class="font-weight-bold d-flex m-0 pl-1">
                {{ data.item.frontdesks.name }}
                {{ data.item.frontdesks.lastname }}
              </p>
            </b-link>
          </div>
        </template>

        <template #cell(oma)="data">
          <b-media v-if="data.item.office_visit.omas" vertical-align="center">
            <div class="d-flex justify-content-start align-items-center">
              <b-avatar size="32" :src="data.item.office_visit.omas.avatar" />
              <b-link :to="{
                name: 'web-settings-users',
                params: { id: data.item.office_visit.omas.id },
              }" class="text-left">
                <p class="font-weight-bold d-flex m-0 pl-1">
                  {{ data.item.office_visit.omas.name }}
                  {{ data.item.office_visit.omas.lastname }}
                </p>
              </b-link>
            </div>
          </b-media>
        </template>

        <template #cell(copay)="data">
          <div class="text-nowrap" v-b-tooltip.html.hover.top="getMovement(data.item.copay_movement)">
            <span>{{ data.item.copay }}</span>
          </div>
        </template>

        <template #cell(time)="data">
          <div class="text-nowrap" v-b-tooltip.html.hover.top="getTimesTooltip(data.item.office_visit.visit_status_auditor)
            ">
            <span>{{ data.item.progresstime }}</span>
          </div>
        </template>

        <template #cell(paid)="data">
          <div class="text-nowrap" v-b-tooltip.html.hover.top="getMovement(data.item.all_movement)">
            <span class="font-weight-bolder" :class="[
      `text-${resolveUserPayStatusVariant(data.item.pay_status_id)}`]" >{{ data.item.paid }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button v-if="
              $can(
                'appointment_cancel',
                'module_patient.resource_schedule'
              ) && data.item.office_visit.visit_statuses_id < 5
            " @click="scanPromotion(data.item.id)" variant="flat-primary" class="btn-icon btn-sm"
              v-b-tooltip.hover.top="`Scan CUPON Qr`">
              <feather-icon icon="CameraIcon" size="16" />
            </b-button>
            <b-button v-if="isCheckPay(data.item)" variant="flat-success" class="btn-icon btn-sm"
              v-b-tooltip.hover.top="'Pay'" @click="payAppointment(data.item)">
              <feather-icon icon="DollarSignIcon" size="16" />
            </b-button>

            <b-button v-if="isCheckPay(data.item) && isCheckPayCopay(data.item)" variant="flat-success"
              class="btn-icon btn-sm" v-b-tooltip.hover.top="'Pay Co-pay'" @click="payAppointmentCopay(data.item)">
              <img src="@/assets/images/icons/report-money.svg" />
            </b-button>
            <!-- <b-button
              variant="flat-success"
              class="btn-icon btn-sm"
              v-b-tooltip.hover.top="'Add Memberships'"
              @click="addMembership(data.item)"
            >
              <feather-icon icon="CreditCardIcon" size="16" />
            </b-button> -->
            <b-button v-if="
              data.item.office_visit.visit_statuses_id === 1 &&
              $can('appointment_cancel', 'module_patient.resource_schedule')
            " variant="flat-danger" class="btn-icon btn-sm" v-b-tooltip.hover.top="'Cancel'"
              @click="cancelAppointment(data.item)">
              <feather-icon icon="XCircleIcon" size="16" />
            </b-button>
            <b-button v-if="$can('appointment_pay', 'module_patient.resource_schedule')" :to="{
              name: 'recipe-patient',
              params: { recipeId: data.item.id },
            }" variant="flat-primary" class="btn-icon btn-sm" v-b-tooltip.hover.top="'Receipt'">
              <feather-icon icon="PrinterIcon" size="16" />
            </b-button>
          </div>
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import GoBack from "@/components/ButtonBack";
import SpinnerLoading from "@/components/SpinnerLoading";
import TitleTable from "@/components/TitleTable.vue";
import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";
import LoadingTable from "@/components/LoadingTable.vue";
import scanQr from "@/components/scanQr.vue";

import AppointmentAdd from "@/views/appointment/appointment-actions/AppointmentAdd.vue";
import AppointmentEdit from "@/views/appointment/appointment-actions/AppointmentEdit.vue";
import AppointmentFilter from "@/views/appointment/appointment-actions/AppointmentFilter.vue";
import AppointmentPay from "./AppointmentPay.vue";
import AppointmentCancel from "./AppointmentCancel";
import AppointmentPayCopay from "./AppointmentPayCopay";

import axiosA from "@/services/appointment";

export default {
  components: {
    scanQr,
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    vSelect,

    GoBack,
    SpinnerLoading,
    ToastNotification,
    PaginationTable,
    TitleTable,
    LoadingTable,

    AppointmentAdd,
    AppointmentEdit,
    AppointmentFilter,
    AppointmentPay,
    AppointmentCancel,
    AppointmentPayCopay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: "show", class: "th-class-adjusted-appointment" },
        { key: "id", sortable: true, class: "th-class-adjusted-appointment" },
        {
          key: "patients",
          label: "Patient",
          sortable: true,
          class: "th-class-adjusted-appointment names",
        },
        {
          key: "patients.date_birth",
          label: "DOB",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "visit_date",
          label: "DOS",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "type_visits",
          label: "Type Visit",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "facilities",
          label: "Facility",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "frontdesks",
          label: "Front-Desk",
          sortable: true,
          class: "th-class-adjusted-appointment names",
        },
        {
          key: "oma",
          label: "OMA",
          sortable: true,
          class: "th-class-adjusted-appointment names",
        },
        {
          key: "status",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "time",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "copay",
          label: "Co-pay",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "ajustment",
          label: "Adjusment",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "balance",
          label: "Balance",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        {
          key: "paid",
          label: "Pay",
          sortable: true,
          class: "th-class-adjusted-appointment",
        },
        { key: "actions", class: "th-class-adjusted-appointment actions" },
      ],
      checkCodeQrModal: false,
      userAppointmentId: 0,
      items: null,
      perPageOptions: [10, 25, 50, 100],
      statusOptions: [
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false" },
      ],
      perPage: 50,
      loading: false,
      isSortDirDesc: true,
      sortBy: "id",
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isAddNewAppointmentSidebarActive: false,
      isEditAppointmentSidebarActive: false,
      conditionPayAppointment: false,
      conditionCancelAppointment: false,
      conditionPayCopayAppointment: false,
      itemToPay: null,
      itemToPay: null,
      filtro: false,
      appointmentFilter: null,
    };
  },
  created() {
    const rolMain = JSON.parse(localStorage.getItem("userData")).roles[0];
    if (rolMain.id !== 1) this.fields.splice(6, 1);
    const channel = this.$pusher.subscribe(
      `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
    );
    channel.bind("officeVisitEvent", ({ event }) => {
      if (!this.filtro) {
        if (event.type === "office_visiit" || event.type === "appointment") {
          this.getAppointments();
        }
      }
    });
    this.getAppointments();
  },
  destroyed() {
    this.$pusher.unsubscribe(
      `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
    );
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getAppointments();
      } else {
        this.searchFilter(this.appointmentFilter);
      }
    },
    "$variableGlobal.facility": function (value) {
      this.getAppointments();
      // pusher
      this.$pusher.unsubscribe(
        `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
      );
      const channel = this.$pusher.subscribe(
        `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
      );
      channel.bind("officeVisitEvent", ({ event }) => {
        if (!this.filtro) {
          if (event.type === "office_visiit" || event.type === "appointment") {
            this.getAppointments();
          }
        }
      });
    },
  },
  methods: {
    scanPromotion(id) {
      if (this.checkCodeQrModal) {
        this.codeQr = null;
      }
      this.userAppointmentId = id;
      this.checkCodeQrModal = !this.checkCodeQrModal;
    },
    closeModalMembership(data) {
      this.codeQr = data;
    },
    getAppointments() {
      axiosA
        .appointmentList(this.perPage)
        .then(({ total, current_page, data, ...res }) => {
          const dataReturned = data.map((item) => {
            if (item.is_insurance_appointment) {
              return {
                ...item,
                _rowVariant: "insurance",
              };
            }
            if (item.membership) {
              return {
                ...item,
                _rowVariant: "membership",
              };
            }
            return item;
          });
          this.items = dataReturned;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    resolveUserStatusVariant(status) {
      if (status) return "success";
      return "danger";
    },
    resolveUserPayStatusVariant(status) {
      if (status === 1) return "success";
      if (status === 2) return "unpaid";
      if (status === 3) return "unpaid";
      if (status === 4) return "secondary";
      if (status === 5) return "primary";
      return "unpaid"
    },
    isCheckPay(item) {
      if (
        item.pay_status_id === 1 ||
        item.pay_status_id === 4 ||
        item.office_visit.visit_statuses_id === 7
      ) {
        return false;
      } else {
        return true;
      }
    },
    isCheckPayCopay(item) {
      return (
        (item.copay === null || item.copay === 0) &&
        item.is_insurance_appointment
      );
    },
    createAppointment({ type, message }) {
      this.NotificationCheck({ type, message });

      if (type === "danger") {
        this.isAddNewAppointmentSidebarActive = false;
      }
      this.pageChanged(this.currentPage.page);
    },
    deleteAppointment(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosA.appointmentDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Appointment has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.appointmentFilter = value;
        axiosA
          .appointmentFilter(this.perPage, value)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false;
            const dataReturned = data.map((item) => {
              if (item.is_insurance_appointment) {
                return {
                  ...item,
                  _rowVariant: "insurance",
                };
              }
              if (item.membership) {
                return {
                  ...item,
                  _rowVariant: "membership",
                };
              }
              return item;
            });
            this.items = dataReturned;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getAppointments();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosA
          .appointmentPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            const dataReturned = data.map((item) => {
              if (item.is_insurance_appointment) {
                return {
                  ...item,
                  _rowVariant: "insurance",
                };
              }
              if (item.membership) {
                return {
                  ...item,
                  _rowVariant: "membership",
                };
              }
              return item;
            });
            this.items = dataReturned;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosA
          .appointmentFilterPagination(
            this.perPage,
            page,
            this.appointmentFilter
          )
          .then(({ registro: { data, total, current_page, ...res } }) => {
            const dataReturned = data.map((item) => {
              if (item.is_insurance_appointment) {
                return {
                  ...item,
                  _rowVariant: "insurance",
                };
              }
              if (item.membership) {
                return {
                  ...item,
                  _rowVariant: "membership",
                };
              }
              return item;
            });
            this.items = dataReturned;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    payAppointment(item) {
      this.itemToPay = item;
      this.conditionPayAppointment = true;
    },
    payAppointmentCopay(item) {
      this.itemToPay = item;
      this.conditionPayCopayAppointment = true;
    },
    addMembership(item) {
      //console.log(item);
    },
    cancelAppointment(item) {
      this.itemToPay = item;
      this.conditionCancelAppointment = true;
    },
    closeModalPay() {
      this.conditionPayAppointment = false;
      this.conditionCancelAppointment = false;
      this.conditionPayCopayAppointment = false;
      this.itemToPay = null;
      this.pageChanged(this.currentPage.page);
    },
    paymentChecked(value) {
      this.pageChanged(this.currentPage.page);
    },
    getTimesTooltip(item) {
      /* nombre - tiempo (2023-04-10T22:05:16.000000Z = 22:05:16 sin moment)
        nombre - tiempo  */
      let times = "";
      item.forEach((time) => {
        times += `${time.prefix} - ${time.time} <br>`;
      });
      return times;
    },
    successModalQr({
      registro,
      status,
      qr,
      message = "Cupon or campaign not found!",
    }) {
      if (status != "ERROR") {
        this.pageChanged(this.currentPage.page);
        this.NotificationCheck({ type: "success", message })
        this.checkCodeQrModal = !this.checkCodeQrModal;
        return
      }
      this.NotificationCheck({ type: "danger", message })
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message);
    },
    handleUpdateAppoiment(value) {
      this.conditionPayAppointment = false;
      this.conditionCancelAppointment = false;
      this.itemToPay = null;

      setTimeout(() => {
        this.itemToPay = value;
        this.conditionPayAppointment = true;
      }, 200);
    },
    getMovement(objectTime){
      return `cash ${objectTime.cash} <br> cc ${objectTime.cc}`
    }
  },
};
</script>
<style lang="scss">
.th-class-adjusted-appointment {
  padding: 0.5rem 0 !important;
}

.th-class-adjusted-appointment.actions {
  padding: 0.7rem 0 !important;
}

.th-class-adjusted-appointment.names {
  width: 12rem;
}

.table-expand .card-header {
  padding: 0 1rem !important;
}

.table-insurance {
  background: rgba(51, 222, 77, 0.251) !important;
  background: linear-gradient(140deg,
      rgba(51, 222, 77, 0.251) 0%,
      rgba(255, 255, 255, 0) 12%) !important;
}

.table-membership {
  background: rgb(241, 197, 0);
  background: linear-gradient(140deg,
      rgba(241, 197, 0, 1) 0%,
      rgba(255, 255, 255, 0) 12%);
}
</style>
