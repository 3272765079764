import { render, staticRenderFns } from "./AppointmentPayCopay.vue?vue&type=template&id=23d569c2&scoped=true&"
import script from "./AppointmentPayCopay.vue?vue&type=script&lang=js&"
export * from "./AppointmentPayCopay.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentPayCopay.vue?vue&type=style&index=0&id=23d569c2&lang=scss&scoped=true&"
import style1 from "./AppointmentPayCopay.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d569c2",
  null
  
)

export default component.exports