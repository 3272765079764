<template>
  <b-modal
    v-model="conditionCancel"
    @hide="onHide"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <validation-observer #default="{ handleSubmit }">
      <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit(onSubmitCancel)">
        <!-- internal payment note-->
        <validation-provider
          #default="validationContext"
          name="Note"
          rules="required"
        >
          <b-form-group
            label="Note"
            label-for="internal-payment-note"
            class="my-1"
          >
            <b-form-textarea
              id="internal-payment-note"
              v-model="noteAppointment"
              placeholder="Internal payment note"
              no-resize
              rows="3"
              :class="{
                'is-invalid':
                  validationContext.touched && !validationContext.valid,
              }"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <div class="d-flex justify-content-end mt-2">
          <b-button
            type="button"
            class="mr-2"
            variant="outline-danger"
            @click="onHide"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="danger" type="submit" :disabled="loading">
            <span v-if="!loading">Send</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosOA from "@/services/appointment/actions";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,

    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    itemAppointment: {
      type: Object,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      conditionCancel: true,
      conditionSplit: true,
      loading: false,
      noteAppointment: "",
    };
  },
  methods: {
    onHide() {
      this.$emit("closeModalPay", false);
    },
    async onSubmitCancel() {
      this.loading = true;
      const data = {
        note: this.noteAppointment,
      };
      await axiosOA
        .appointmentCancel(this.itemAppointment.id, data)
        .then((res) => {
          const { type, message } =
            NotificationMixin.methods.notificationCheck(res);
          this.loading = false;
          this.$emit("paymentChecked", true);
          this.$emit("NotificationCheck", { type, message });
        })
        .catch((e) => {
          this.loading = false;
        });
        this.$emit("closeModalPay", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>