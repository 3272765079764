import { userAxios } from '@/services'

const visitStatusList = async (perPage) => {
    try {
        return await userAxios.get(`visit_status/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitStatusCreate = async (data) => {
    try {
        return await userAxios.post('visit_status', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitStatusUpdate = async (id, data) => {
    try {
        return await userAxios.put(`visit_status/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitStatusDelete = async (id) => {
    try {
        return await userAxios.delete(`visit_status/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitStatusPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`visit_status/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitStatusFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`visit_status/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const visitStatusFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`visit_status/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    visitStatusList,
    visitStatusCreate,
    visitStatusUpdate,
    visitStatusDelete,
    visitStatusPagination,
    visitStatusFilter,
    visitStatusFilterPagination
}