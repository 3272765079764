<template>
  <b-modal
    v-model="conditionPay"
    @hide="onHide"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <validation-observer ref="payRef">
      <b-form class="auth-login-form mt-2" @submit.prevent="onSubmitPay">
        <div class="d-flex justify-content-between align-items-center">
          <b-form-group label="Remaining" label-for="total">
            <b-form-input
              id="total"
              name="total"
              disabled
              :value="totalRemaining"
            />
          </b-form-group>
          <span class="mx-1">+</span>
          <b-form-group label="Convenience Fee" label-for="Convenience">
            <b-form-input
              id="Convenience"
              name="Convenience"
              disabled
              :value="convenienceFee"
            />
          </b-form-group>
          <span class="mx-1"> = </span>
          <b-form-group label="Total" label-for="total">
            <b-form-input
              id="total"
              name="total"
              disabled
              :value="totalPrice"
            />
          </b-form-group>
        </div>

        <!-- payment day -->
        <validation-provider
          #default="{ errors }"
          name="Payment Date"
          rules="required"
          ref="dateRules"
        >
          <b-form-group label="Payment Date" label-for="payment-date">
            <flat-pickr
              v-model="paymentInfo.paymentDate"
              :config="config"
              class="form-control w-100"
              placeholder="YYYY-MM-DD"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- payment method -->
        <div class="my-1">
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Credit Card">
                <b-form-group label="Credit Card Value" label-for="credit-card">
                  <b-form-input
                    id="credit-card"
                    name="credit-card"
                    v-model.number="creditCardAmount"
                    :state="errors.length > 0 ? false : null"
                    @change="addConvenienceFee"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col
            >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="CC Payment Id"
                :rules="creditCardAmount != 0 ? 'required|min:4' : null"
              >
                <b-form-group label="CC Payment Id" label-for="cash">
                  <b-form-input
                    name="Payment Id"
                    v-model="CreditCloverId"
                    :state="errors.length > 0 ? false : null"
                    :disabled="!creditCardAmount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col
            >
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Cash">
                <b-form-group label="Cash" label-for="cash">
                  <b-form-input
                    id="cash"
                    name="cash"
                    v-model.number="cashAmount"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col
            >
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Cash Payment Id"
                :rules="cashAmount != 0 ? 'required|min:4' : null"
              >
                <b-form-group label="Cash Payment Id" label-for="cash">
                  <b-form-input
                    name="Payment Id"
                    v-model="cashCloverId"
                    :disabled="!cashAmount"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col
            >
          </b-row>
        </div>

        <div class="d-flex justify-content-end mt-2">
          <b-button
            type="button"
            class="mr-2"
            variant="outline-danger"
            @click="onHide"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            :disabled="loading || itemAppointment.pay_status_id === 1"
          >
            <span v-if="!loading">Pay</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosOV from "@/services/office-visit/index";
import Ripple from "vue-ripple-directive";
import axiosA from "@/services/appointment";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckbox,

    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    itemAppointment: {
      type: Object,
      required: true,
    },
    isInsurance: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  directives: {
    Ripple,
  },
  watch: {
    firstCC() {
      if (this.firstCC)
        this.$swal({
          title: "IMPORTANT",
          text: "The convenience fee will be charged to Self pay patients who pay by credit or debit card. Patients with insurance will only be charged the convenience fee when paying by credit card. CONVENIENCE FEE WILL NEVER BE CHARGED FOR CASH PAYMENTS",
          icon: "warning",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
    },
  },
  computed: {
    getTotal() {
      return this.itemAppointment.copay;
    },
    splitPay() {
      return parseFloat(this.cashAmount) + parseFloat(this.creditCardAmount);
    },
    totalPrice() {
      return parseFloat(this.convenienceFee) + parseFloat(this.totalRemaining);
    },
    amountCheck: {
      get() {
        if (this.paymentInfo.paymentMethod == 1) {
          this.convenienceFee = 0;
          return this.cashAmount;
        } else {
          this.addConvenienceFee();
          return this.creditCardAmount;
        }
      },
      set(val) {
        if (this.paymentInfo.paymentMethod == 1) {
          this.cashAmount = val;
          this.creditCardAmount = 0;
        } else {
          this.cashAmount = 0;
          this.creditCardAmount = val;
        }
      },
    },
    totalRemaining() {
      return parseFloat(this.creditCardAmount) + parseFloat(this.cashAmount)
    },
  },
  data() {
    return {
      conditionPay: true,
      conditionSplit: false,
      firstCC: false,
      methodAxios: "procedurePay",
      cashCloverId: "",
      CreditCloverId: "",
      paymentInfo: {
        paymentDate: new Date(),
        paymentMethod: null,
      },
      paymentMethodOptions: [
        {
          name: "Cash",
          id: 1,
        },
        {
          name: "Credit Card",
          id: 2,
        },
      ],
      convenienceFee: 0,
      cashAmount: 0,
      creditCardAmount: 0,
      config: {
        static: true,
      },
      loading: false,
    };
  },
  mounted() {
    if (this.itemAppointment.cc != null && this.itemAppointment.cash != null) {
      this.conditionSplit = false;
    } else if (
      this.itemAppointment.cc != null &&
      this.itemAppointment.cash == null
    ) {
      this.paymentInfo.paymentMethod = 2;
    } else {
      this.paymentInfo.paymentMethod = 1;
    }
    if (this.itemAppointment.pay_status_id === 1) {
      this.cashAmount = this.itemAppointment.cash;
      this.creditCardAmount = this.itemAppointment.cc;
    }
  },
  methods: {
    onHide() {
      this.$emit("closeModalPay", false);
    },
    onSubmitPay() {
      this.$refs.payRef.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          const data = {
            payment: {
              cash: {
                value: this.cashAmount ? this.cashAmount : 0,
                transaction_id: this.cashCloverId ? this.cashCloverId : "",
              },
              cc: {
                value: this.creditCardAmount ? this.creditCardAmount : 0,
                transaction_id: this.CreditCloverId ? this.CreditCloverId : "",
              },
              convenience_fee: this.convenienceFee,
              is_split_pay: !this.conditionSplit,
            },
          };
          await axiosA.appointmentPayCopay(this.itemAppointment.id, data)
            .then((res) => {
              const { type, message } =
                NotificationMixin.methods.notificationCheck(res);
              this.loading = false;
              this.$emit("NotificationCheck", { type, message });
              this.$emit("paymentChecked", true);
              return this.createRecipeInsurancePayment()
            })
            .catch((e) => {
              this.loading = false;
            });
          this.$emit("closeModalPay", false);
        } else {
          console.log("No");
        }
      });
    },
    validationMethod() {
      return new Promise((resolve, reject) => {
        this.$refs.dateRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    addConvenienceFee() {
      this.firstCC = true;
      const totalConvenience = (this.creditCardAmount * 3) / 100;
      this.convenienceFee = parseFloat(totalConvenience.toFixed(2));
    },
    createRecipeInsurancePayment(){
      const data = {
        ...this.itemAppointment.patients,
        payment: this.creditCardAmount + this.cashAmount,
        total: this.creditCardAmount + this.cashAmount,
        convenience_fee: this.convenienceFee
      };

      const bloblstateData = btoa((JSON.stringify(data)))
      const routeData = this.$router.resolve({
        name: "recipe-insurance-payment",
        query: { data: bloblstateData },
      });
      
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.flatpickr-wrapper {
  width: 100% !important;
}
</style>
<style lang="scss">
.flatpickr-wrapper {
  width: 100% !important;
}
</style>
